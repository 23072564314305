* {
  font-family: Rubik, sans-serif;
}

// body:fullscreen {
//   overflow: scroll !important;
// }
// body:-ms-fullscreen {
//   overflow: scroll !important;
// }
// body:-webkit-full-screen {
//   overflow: scroll !important;
// }
// body:-moz-full-screen {
//   overflow: scroll !important;
// }
body {
  position: sticky;
  overflow-y: overlay;
}
ol li {
  list-style: none;
}

#root .cesium-widget-credits {
  display: none;
}

#xd-logo {
  color: white;
  font-weight: bolder;
  font-size: 18px;
  margin-right: 6px;
}

.ant-drawer-mask {
  background-color: rgba(0, 0, 0, 0.15);
}

#mainDrawer {
  .ant-drawer-close {
    height: 70px;
  }
  .ant-drawer-title {
    height: 40px;
    line-height: 40px;
  }
  #demo1,
  #demo2 {
    width: 100%;
  }
  .ant-drawer-content {
    overflow: hidden;
  }
  .ant-drawer-wrapper-body {
    position: relative;
  }
  .ant-drawer-body {
    height: calc(100vh - 73px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 24px 24px 80px;
    .username {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px 140px 10px 24px;
      background-color: #fafafa;
      border-top: 1px solid #e8e8e8;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .sticky {
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: #fafafa;
      border: 1px solid #e8e8e8;
      border-bottom: none;
      border-right: none;
      padding: 10px 24px;
      .anticon {
        margin-right: 5px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.views-drawer {
  .ant-drawer-content-wrapper {
    height: auto !important;
    .ant-drawer-content {
      background-color: rgba(38, 38, 38, 0.75);
      .ant-drawer-wrapper-body {
        overflow: hidden;
        .ant-drawer-header {
          border-top: 1px solid #444;
          border-bottom: 1px solid #444;
          background-color: transparent;
        }
      }
    }
  }
  .slick-slider {
    .slick-slide {
      padding: 0 10px;
    }
    .slick-arrow {
      z-index: 1;
      &:before {
        font-size: 40px;
        transition: ease 0.3s;
      }
      &:hover {
        &:before {
          opacity: 1;
          transition: ease 0.3s;
        }
      }
      &.slick-next {
        right: 0;
      }
      &.slick-prev {
        left: -20px;
      }
      &.slick-disabled {
        opacity: 0;
        transition: ease 0.3s;
      }
    }
  }
  .anticon-close {
    color: #fff;
  }
}

.avatar-uploader {
  &.has-image {
    .ant-upload {
      width: auto !important;
      max-width: 250px;
      height: auto !important;
      > span {
        display: block !important;
        padding: 0 !important;
      }
    }
  }
}

#setting-control {
  background-color: rgba(222, 222, 222, 0.75);
  padding: 10px;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 2px;
  overflow: auto;
  height: 97%;
  @media screen and (max-width: 768px) {
    padding: 8px;
  }
  .ant-btn-icon-only {
    margin-left: 5px;
  }
  .ant-cascader-picker,
  .ant-btn-icon-only {
    margin-bottom: 10px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
}

.custom-ant-dropdown-menu {
  * {
    color: white;
  }
  .menu-wrapper {
    background-color: rgba(38, 38, 38, 0.75);
    padding: 15px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    // min-width: 240px;
    &:before {
      display: block;
      content: '';
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom: 10px solid rgba(38, 38, 38, 0.75);
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .menu-title {
    color: white;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  .ant-collapse {
    border: none;
    background-color: transparent;
    color: white;
    .ant-collapse-item {
      border: 1px solid #545454;
      border-radius: 5px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      .ant-collapse-header {
        color: white;
        padding: 5px 10px 5px 30px;
        font-size: 13px;
        .ant-collapse-arrow {
          left: 10px;
        }
      }
      .ant-collapse-content {
        background-color: transparent;
        color: white;
        border-top: 1px solid #545454;
        .ant-collapse-content-box {
          @media screen and (max-width: 768px) {
            padding: 10px;
          }
        }
      }
    }
  }
}

.pageDetailDrawer {
  .tileset-name {
    font-weight: 500;
    margin-bottom: 5px;
    color: #191919;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 190px;
  }
  .ant-drawer-close {
    height: 100%;
  }
  .ant-drawer-body {
    margin-right: 8px;
    padding-top: 10px;
    .ant-table-wrapper {
      position: relative;
      left: -24px;
      top: -24px;
    }
    .ant-table {
      table {
        width: calc(100% + 48px);
      }
    }
    .ant-table-selection-col,
    .ant-table-selection-column {
      width: 30px;
      padding: 0 0 0 10px !important;
    }
    .ant-table-row-selected {
      td {
        background-color: #e1eded;
      }
    }
    td {
      padding: 0 10px;
    }
  }
  .ant-tree {
    .ant-tree-node-content-wrapper {
      .ant-tree-iconEle {
        width: auto;
        .ant-avatar {
          background: none !important;
          height: auto !important;
          line-height: inherit !important;
        }
        .hide {
          svg {
            path {
              fill: rgb(151, 160, 175);
            }
          }
        }
      }
      white-space: nowrap;
    }
  }
}

.cesium-viewer-animationContainer {
  display: none;
}

.cesium-viewer-geocoderContainer {
  // position: fixed;
  // bottom: 10px;
  // left: 5px;
  // .search-results {
  //   bottom: 100%;
  // }
}

.model-setting-dropdown {
  .setting-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    > span {
      margin-right: 10px;
      width: 150px;
      line-height: 1;
    }
    .ant-switch-small .ant-switch-inner {
      font-size: 11px;
    }
  }
}

.ant-popover {
  z-index: 99999;
}

#editModelPanel {
  .ant-form-item {
    margin-bottom: 5px;
  }
}

#rotationPanel {
  .ant-input-number {
    width: 70px;
  }
}

.cesium-viewer-fullscreenContainer {
  width: 37px;
  height: 41px;
  padding: 0px 7px 11px 0;
  position: fixed;
}
.ant-drawer {
  z-index: 9999;
}
.ant-dropdown {
  z-index: 99999;
}

.hidden-tiles-drawer .ant-drawer-body {
  padding: 0;
}

#editSketchPanel {
  z-index: 9999;
}
.ant-calendar-picker-container {
  z-index: 9999;
}
.ant-picker-dropdown {
  z-index: 99999;
}
.ant-select-dropdown {
  z-index: 99999;
}
.ant-message {
  z-index: 9999;
}
.text-c {
  text-align: center;
}

.distance-legend {
  left: 5px;
  bottom: 10px;
}
.popover-ar {
  left: auto !important;
  right: 40px;
  .ant-popover-inner-content {
    padding: 0;
  }
}

.popover-ar .ant-popover-inner,
.popover-ar-mobile .ant-popover-inner {
  background: none !important;
}

.popover-ar-mobile {
  .ant-popover-inner-content {
    padding: 0;
  }
}

.notifile-style {
  .ant-notification-notice-message {
    margin-bottom: 0px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}
.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
  i {
    margin-right: 5px;
  }
}
.cesium-performanceDisplay-fps {
  color: yellow;
}
.cesium-performanceDisplay-defaultContainer {
  top: 136px;
  right: 21px;
}

@media (max-height: 540px) {
  .cesium-performanceDisplay-defaultContainer {
    top: 136px;
    right: 46px;
  }
}

.cesium-viewer-vrContainer {
  bottom: 140px;
  width: 37px;
  height: 41px;
  padding: 0px 7px 11px 0;
  position: fixed;
  right: 0 !important;
}
.ant-modal-footer {
  text-align: left;
}
#artest-page {
  .ant-layout-header {
    height: 50px;
    line-height: 50px;
    color: #222;
    padding: 0;
    border-bottom: 1px solid #999 !important;
    background: #fff;

    .ant-switch {
      // position: absolute;
      float: right;
      margin-right: 10px;
      margin-top: 15px;
    }
  }
}

#opencv2-control {
  .ant-input-number,
  .ant-switch {
    margin-bottom: 8px;
  }
}
#addAModal-control {
  overflow: auto;
  padding: 0;
}
.addArModal {
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-footer {
    text-align: right;
  }
}
.ant-tooltip {
  z-index: 9999;
}

#bgTask {
  border: #4e3a3a !important;
  background-color: #4a4a4ab0;
  color: white;
}
.model-topic {
  margin: 15px;
}
.text-right {
  text-align: right;
}
.box-dragable {
  padding: 0 10px 10px 10px;
}
.dragcontainer {
  max-height: 400px;
  overflow: auto;
  display: flex;
  .ant-btn {
    padding: 0 7px;
  }
}
.pr-5 {
  padding-right: 5px;
}
.filterbutton {
  margin: 0 auto;
  float: right;
  margin-right: 0 !important;
}

.splitpanel {
  top: 0;
  left: 0;
  width: 10px;
  bottom: 0;
  cursor: ew-resize;
  padding: 4px 0 0;
  z-index: 100;
  position: absolute;
  border-top: 1px solid #ddd;
  background-color: #f2cfc4;
}

.splitpanel-mobile {
  top: 0;
  left: 0;
  bottom: 0;
  cursor: ew-resize;
  padding: 4px 0 0;
  z-index: 100;
  position: absolute;
  .btnSplitPanel {
    position: absolute;
    top: 50%;
    margin-left: -15px;
    padding: 0 !important;
  }
}
.drawerObjectDetail {
  .ant-drawer-body {
    padding: 20px 0px 20px 5px;
    margin-right: 8px;
  }
  .ant-tree-child-tree > li:first-child {
    padding-top: 0;
  }
  .ant-tree li {
    padding: 0;
  }
}
.pageDetailDrawer,
.drawerObjectDetail,
.wrapAttributeModal {
  .splitpanel,
  .splitpanel-mobile {
    right: 0;
    left: auto;
  }
  .splitpanel-mobile {
    .btnSplitPanel {
      margin-left: -16px;
    }
  }
}
.ant-table-tbody {
  tr {
    td {
      padding: 12px 16px;
    }
    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      td {
        background-color: #fff !important;
      }
    }
  }
}

.my-modal-class {
  .ant-modal-footer {
    text-align: right !important;
  }
}

.custom-slider {
  margin: 14px 0px 10px;
  .ant-slider-rail {
    height: 10px;
  }
  .ant-slider-track {
    height: 10px;
  }
  .ant-slider-handle {
    width: 20px;
    height: 20px;
  }
}

.css-link-delete {
  color: rgb(246, 77, 10);
  font-weight: 500;
  cursor: pointer;
}
.replyto {
  float: right;
  bottom: 0;
  margin-top: 2px;
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  background-color: #fff;
  color: #f26524;
}
.ant-comment-inner {
  padding: 5px 0 !important;
  .ant-avatar {
    width: 28px;
    height: 28px;
    line-height: 28px;
  }
}

.drawerTopicList {
  .splitpanel-horizontal {
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    cursor: n-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    border-top: 1px solid #ddd;
    background-color: #f2cfc4;
  }

  .splitpanel-horizontal-mobile {
    top: 0;
    left: 50%;
    width: 100%;
    cursor: n-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    .btnSplitPanel {
      position: absolute;
      top: 50%;
      margin-top: -15px;
      padding: 0 !important;
    }
  }

  .toggleFullScreen {
    margin-right: 40px;
    position: absolute;
    right: 0;
    width: 30px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    position: absolute;
    top: 0;
    display: block;
    width: 30px;
    height: 56px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    text-rendering: auto;
    &:hover,
    &:focus {
      color: rgba(0, 0, 0, 0.75);
    }
  }
  .ant-drawer-close {
    width: 30px;
    right: 10px;
  }
  // .ant-drawer-content-wrapper{
  //   overflow-y: auto;
  //   background-color: #fff;
  // }
}

.link-model-tileset .ant-collapse-content-box {
  word-wrap: break-word;
}
.wrapAttributeModal {
  .ant-modal-header {
    border-bottom: none;
    .ant-modal-title {
      margin-left: -24px;
      margin-right: -14px;
      margin-bottom: -16px;
      margin-top: -16px;
    }
  }
  .ant-modal-footer {
    .ant-btn {
      &.ant-btn-primary {
        display: none;
      }
    }
  }
}
.readOnly {
  pointer-events: none;
}

.custom-ant-dropdownmenu {
  * {
    color: white;
  }
  .menu-wrapper {
    background-color: rgba(38, 38, 38, 0.75);
    padding: 15px;
    border-radius: 5px;
    top: -36px;
    margin-left: 50px;
    position: absolute;
    &:before {
      display: block;
      content: '';
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-width: 10px 15px 10px 0;
      border-color: transparent rgba(38, 38, 38, 0.75) transparent transparent;
      position: absolute;
      left: -15px;
      top: 7px;
    }
  }
  .menu-title {
    color: white;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  .ant-collapse {
    border: none;
    background-color: transparent;
    color: white;
    .ant-collapse-item {
      border: 1px solid #545454;
      border-radius: 5px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      .ant-collapse-header {
        color: white;
        padding: 5px 10px 5px 30px;
        font-size: 13px;
        .ant-collapse-arrow {
          left: 10px;
        }
      }
      .ant-collapse-content {
        background-color: transparent;
        color: white;
        border-top: 1px solid #545454;
        .ant-collapse-content-box {
          @media screen and (max-width: 768px) {
            padding: 10px;
          }
        }
      }
    }
  }
}
.model-setting-dropdownmenu {
  .setting-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    > span {
      margin-right: 10px;
      width: 150px;
      line-height: 1;
    }
    .ant-switch-small .ant-switch-inner {
      font-size: 11px;
    }
  }
}
.cesium-viewer-vrContainer {
  // hide vrbutton for custom
  display: none !important;
}

@media (orientation: portrait) and (hover: none) and (pointer: coarse) {
  .cesium-viewer-geocoderContainer:hover .cesium-geocoder-input,
  .cesium-viewer-geocoderContainer .cesium-geocoder-input:focus,
  .cesium-viewer-geocoderContainer .cesium-geocoder-input-wide {
    width: 155px !important;
  }
}

// located on data-attribute button
.cesium-viewer-geocoderContainer {
  z-index: 999; // higher data-attribute button
  .cesium-geocoder-input {
    background-color: rgba(40, 40, 40, 1);
  }
}

// custom css for compass on mobile landscape
.compass {
  right: 8px !important;
}
@media (orientation: landscape) and (hover: none) and (pointer: coarse) {
  .compass {
    right: 35px !important;
  }
}

.projectDashBoardPage {
  .ant-drawer-content {
    overflow: visible;
  }
  .split-panel-mobile {
    span {
      padding-top: 3px !important;
    }
  }
  .split-panel {
    top: 0;
    left: 0;
    width: 10px;
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    border-top: 1px solid #ddd;
    background-color: #f2cfc4;
  }
  .chart-container {
    background-color: #fff;
    width: 100%;
    .contain-left {
      .topicChart-wraper {
        .topic-status-option {
          padding: 0 40px;
          .topic-title {
            color: #acaeb4;
            font-size: 20px;
          }
        }
        .topic-chart {
          .xLegendSymbol {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 5px solid;
            border-radius: 30px;
            height: 30px;
            width: 30px;
            margin-right: 10px;
            font-size: 11px;
          }
          .highcharts-legend-item {
            rect {
              visibility: hidden;
            }
          }
          .highcharts-legend-item.highcharts-pie-series > :first-child {
            width: 160px;
          }
        }
      }
      .topicType-wraper {
        .topic-title {
          color: #acaeb4;
          font-size: 20px;
          padding: 0 40px;
        }
        .topic-container {
          margin-top: 30px;
        }
      }
      .timeline-chart-wraper {
        .timeline-chart {
          width: 100%;
          .highcharts-legend-item {
            path {
              visibility: hidden;
            }
          }
          .xLegendSymbol {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 1px solid;
            height: 15px;
            width: 30px;
            margin-right: 10px;
            font-size: 11px;
          }
        }
      }
    }
    .contain-right {
      .contain-right-wraper {
        padding: 0 20px;
        width: 100%;
        .workflow-wraper {
          width: 100%;
          .workflow-title {
            color: #acaeb4;
            font-size: 20px;
          }
          .workflow-control {
            width: 100%;
            padding: 20px;
          }
        }
        .workflow-chart-container {
          margin-top: 30px;
          width: 100%;
          .ant-col {
            width: 100%;
          }
        }
      }
      .project-complete-wraper {
        padding: 0 20px;
        width: 100%;
        margin-top: 40px;
        .title {
          font-size: 20px;
          color: #acaeb4;
        }
        .container-chart {
          margin-top: 30px;
          width: 100%;
          .project-complete {
            width: 100%;
            .highcharts-container {
              max-height: 340px;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .dotted {
    display: inline-block;
    width: 30px;
    height: 10px;
    border-radius: 10px;
    margin-right: 5px;
  }

  .dotted.modeling {
    background-color: #55d8fe;
  }

  .dotted.internal {
    background-color: #ff8373;
  }

  .dotted.customer {
    background-color: #ffda83;
  }

  .dotted.construction {
    background-color: #a3a0fb;
  }

  .topic-detail {
    width: 100%;
    max-width: 200px;
    display: flex;
    justify-content: space-between;
  }

  .label-wrap,
  .count-topic {
    display: flex;
    align-items: center;
  }

  .count-topic {
    color: #ddd;
  }

  @media screen and (max-width: 768px) {
    .title_txt,
    .title_name {
      font-size: 20px;
    }
  }
}

#projectDashBoardPage[max-width~='575px'] {
  .topdashboard-item {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .contain-left {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
    .timeline-chart {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .contain-right {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .topicChart-wraper {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .topicType-wraper {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .project-complete .highcharts-container {
    max-height: 240px;
    height: 100%;
  }
  .title_txt,
  .title_name {
    flex-wrap: wrap;
    font-size: 18px;
    width: 100%;
  }
}

#projectDashBoardPage[min-width~='576px'] {
  .topdashboard-item {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .contain-left {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
    .timeline-chart {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .contain-right {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .topicChart-wraper {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .topicType-wraper {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .project-complete .highcharts-container {
    max-height: 240px;
    height: 100%;
  }
}
#projectDashBoardPage[min-width~='768px'] {
  .topdashboard-item {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .contain-left {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .contain-right {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
#projectDashBoardPage[min-width~='992px'] {
  .topdashboard-item {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .contain-left {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .contain-right {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
#projectDashBoardPage[min-width~='1200px'] {
  .topdashboard-item {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .contain-left {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .contain-right {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .topicChart-wraper {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .topicType-wraper {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

#projectDashBoardPage[min-width~='1600px'] {
  .topdashboard-item {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .contain-left {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .contain-right {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .topicChart-wraper {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .topicType-wraper {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.ant-avatar.ant-avatar-icon {
  font-size: 20px;
}

.custom-wraper-splitPanel {
  z-index: 9999;
  .ant-drawer-content {
    overflow: visible;
  }
  .btnSplitPanel {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      padding-top: 3px !important;
    }
  }
}
.ant-tree {
  margin-right: -22px;
  .ant-tree-list-holder-inner {
    -ms-flex-align: stretch;
    align-items: stretch;
    .ant-tree-node-content-wrapper {
      -ms-flex: auto;
      flex: auto;
      .ant-tree-title {
        line-height: 24px;
        margin-left: 5px;
      }
    }
  }
}

.modal-rename-feedback .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-organization {
  .ant-modal-footer {
    text-align: right;
  }
  .ant-row.ant-form-item {
    margin-bottom: 10px;
  }
  .ant-row {
    display: block;
  }

  //Hide input arrow
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .ant-upload.ant-upload-drag {
    width: 150px;
    min-height: 150px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
  }
  .ant-upload.ant-upload-drag .ant-upload-btn {
    display: table;
    height: 100%;
    min-height: 150px;
  }
}

.ant-image-preview-wrap {
  z-index: 9999;
}
#pageDetailDrawer {
  .ant-drawer-close {
    height: 70px;
  }
  .ant-drawer-title {
    height: 40px;
    line-height: 40px;
  }
  .explorer-title {
    font-weight: bolder;
    font-size: 22px;
  }
  .ant-steps-item-content {
    width:auto;
  }
  .ant-upload.ant-upload-drag {
    background: #d2f1f7;
  }
  .ant-upload-list-item-name {
    color:#F26524;
  }
}

.iframe {
  overflow: hidden;
}
@primary-color: #F26524;@link-color: #1da57a;